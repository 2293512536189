import { getStoreFromQueryParam } from "@/utils/store.util";
import * as Sentry from "@sentry/react";

export function setSentryUser({ email }: { email?: string }) {
  Sentry.setUser({ email });
}

export function setSentryTag({
  tagName,
  value,
}: {
  tagName: string;
  value: string;
}) {
  Sentry.setTag(tagName, value);
}

/**
 * Sentry capture exception with tag..
 */
export function sentryCaptureException({
  tag = {
    name: null,
    value: null,
  },
  error,
}: {
  tag: {
    name: string | null;
    value: string | null;
  };
  error: any;
}) {
  const storeName = getStoreFromQueryParam();
  Sentry.withScope((scope) => {
    if (tag?.name && tag?.value) {
      scope.setTag(tag.name, tag.value);
    }
    if (storeName) {
      scope.setTag("store.name", storeName);
    }
    Sentry.captureException(error);
  });
}
