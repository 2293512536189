import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import theme from "./theme";
import NotificationProvider from "./providers/NotificationProvider";
import NavigationScroll from "@/components/molecules/NavigationScroll";
import Routes from "@/routes";
import NotificationComponent from "@/components/molecules/NotificationMessage";
import { MUI_LICENSE_KEY } from "./config/env.config";
import config from "@/config/redux.config";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme({
        borderRadius: config.borderRadius,
        fontFamily: config.fontFamily,
        navType: config.navType,
      })}>
        <NotificationProvider>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
          <NotificationComponent />
        </NotificationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
