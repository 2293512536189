import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { BrowserView, MobileView } from "react-device-detect";
import { drawerWidth } from "@/store/constant";
import MenuList from "@/components/molecules/SidebarSections/MenuList";

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: any) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: any) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerContent = ({ matchUpMd, drawerOpen, drawerToggle }: { matchUpMd: boolean; drawerOpen: any; drawerToggle: any; }) => (
  <>
    <BrowserView>
      <Box
        component="div"
        style={{
          height: !matchUpMd ? "calc(100vh - 0px)" : "calc(100vh - 0px)",
          paddingLeft: drawerOpen ? "16px" : "6px",
          paddingRight: drawerOpen ? "16px" : "6px",
        }}
      >
        <MenuList
          drawerOpen={drawerOpen}
          handleLeftDrawerToggle={drawerToggle}
        />
      </Box>
    </BrowserView>
    <MobileView>
      <Box sx={{ px: 2 }}>
        <MenuList
          drawerOpen={drawerOpen}
          handleLeftDrawerToggle={drawerToggle}
        />
      </Box>
    </MobileView>
  </>
);

const Sidebar = ({ drawerOpen, drawerToggle, window }: any) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 } }}>
      <Drawer
        container={container}
        variant={matchUpMd ? "permanent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "0px",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <DrawerContent
          matchUpMd={matchUpMd}
          drawerOpen={drawerOpen}
          drawerToggle={drawerToggle}
        />
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
