import { useEffect, useState } from "react";
import { useAuthentication } from "@/hooks/authentication/useAuthentication";
import type { IStore } from "@/models/store";
import { SENTRY_TAG_NAME } from "@/config/sentry.config";
import { sentryCaptureException } from "@/services/sentry.service";
import { useAppDispatch, useAppSelector } from "@/store";
import { storeApi } from "@/store/api/store";
import { setSelectedStoreLoaded, setSelectedStores } from "@/store/slices/store";
import { handleInternalServerError } from "@/utils/errorHandler";
import { removeStorage } from "@/utils/storage.util";
import useNotification from "../useNotification";
import { INTERNAL_SERVER_STORAGE_ERROR } from "@/config/global.config";
import { AxiosError } from "axios";

/**
 * Store utility hook
 * It will fetch data from the store api..
 */
export default function useFetchStore() {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const storesList = useAppSelector(state => state.store.storesList);
  const dispatch = useAppDispatch();
  const { addNotification } = useNotification();
  const { isAuthenticated, accessToken } = useAuthentication();
  const [getStores] = storeApi.endpoints.getStores.useLazyQuery();
  const refetchStoreData = async () => {
    try {
      if (!isAuthenticated || !accessToken) {
        return;
      }
      
      setIsLoading(true);

      const data = await getStores({ access_token: accessToken }).unwrap();
      removeStorage(INTERNAL_SERVER_STORAGE_ERROR);

      if (!data?.[0]) {
        setIsFetched(true);
        return;
      }

      dispatch(setSelectedStores(data));
      dispatch(setSelectedStoreLoaded(true));
    } catch (err) {
      const error = err as AxiosError;
      
      handleInternalServerError({ addNotification, error });
      sentryCaptureException({
        tag: {
          name: SENTRY_TAG_NAME.API_NAME,
          value: "Fetch Store",
        },
        error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!storesList?.[0]) {
      refetchStoreData();
    } else {
      setIsFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storesList, accessToken]);

  return {
    stores: storesList as IStore[],
    isLoading,
    isFetched,
    refetchStoreData,
  };
}
