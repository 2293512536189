/**
 * Storage utility: Store a value in the defined storage (e.g., user preferences).
 * @param {string} name - The name/key under which to store the value.
 * @param {string} value - The value to store.
 */
export const setStorage = (name: string, value: string): void => {
  localStorage.setItem(name, value);
};

/**
 * Get the value associated with the given key from the storage.
 * @param {string} name - The name/key for which to retrieve the value.
 * @returns {string | null} - The stored value or null if not found.
 */
export const getStorage = (name: string): string | null => {
  return localStorage.getItem(name);
};

/**
 * Remove the specified key and its associated value from the storage.
 * @param {string} name - The name/key to remove.
 */
export const removeStorage = (name: string): void => {
  localStorage.removeItem(name);
};
