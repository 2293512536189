export const MESSAGES = {
  GENERAL_ERROR_MESSAGE: (
    <span>
      An unintended error occurred. No worries. Please contact{" "}
      <a href="mailto:support@myadscore.com">support@myadscore.com</a>
    </span>
  ),
  GENERAL_ERROR_MESSAGE_REFRESH: (
    <span>
      An unintended error occurred. No worries. Please refresh your browser. Thanks.
    </span>
  ),
  GENERAL_ERROR_MESSAGE_CUSTOMIZED: ({ message }: { message: string }) => (
    <span>
      {message} Please contact{" "}
      <a href="mailto:support@myadscore.com">support@myadscore.com</a>
    </span>
  ),
  NEW_STORE: {
    ADDED_SUCCESSFULLY: {
      TITLE: "Store added successfully!",
      MESSAGE: `Please make a connection to your Facebook Ads and Google Analytics accounts to see your AdScore or use the demo store to review the AdScore functionality.`,
      CONFIRM_BUTTON_TEXT: "Setup",
    },
    ALREADY_EXITS: ({ storeName }: { storeName: string }) => ({
      TITLE: "Already Exists!",
      MESSAGE: (
        <span>
          Store <span className="text-primary text-semibold">{storeName}</span>{" "}
          already exists, kindly create store with another name.
        </span>
      ),
    }),
  },
  DELETE_STORE: {
    SUCCESS: ({ storeName }: { storeName: string }) => ({
      TITLE: "Success!",
      MESSAGE: (
        <span>
          Store <span className="text-primary text-semibold">{storeName}</span>{" "}
          deleted successfully
        </span>
      ),
    }),
    CONFIRMATION: ({ storeName }: { storeName: string }) => ({
      TITLE: "Delete Store!",
      MESSAGE: (
        <span>
          Your store{" "}
          <span className="text-primary text-semibold">{storeName}</span>{" "}
          including all data will be deleted. You can still use your credentials
          to log-in.
        </span>
      ),
    }),
  },
  SETUP_STORE: {
    TITLE: "Store setup!",
    MESSAGE: `Please set-up a new store or use the demo store to review the AdScore functionality.`,
    CONFIRM_BUTTON_TEXT: "Setup",
  },
  FB_GA_CONNECTION_SUCCESS: {
    TITLE: "",
    MESSAGE: `The connection to the data source is being prepared and data is extracted. You will be informed by email, when the process is completed - takes upto 30 min. You will be logged-out automatically when pressing okay.`,
  },
  FB_GA_UNIVERSAL_CONNECTION: {
    TITLE: "GA4 is not enabled!",
    MESSAGE:
      "AdScore cannot identify a GA4 property with your account. Please check, if GA4 is enabled for your store and connect GA4 again.",
  }, // Not displaying anymore..
  ACCOUNT_MANAGEMENT: {
    INVITATION: {
      ALREADY_EXISTS: ({ email }: { email: string }) => ({
        MESSAGE: (
          <span>
            Team member{" "}
            <span className="text-primary text-semibold">{email}</span> is
            already invited.
          </span>
        ),
      }),
      SUCCESS: ({ email }: { email: string }) => ({
        TITLE: "Success!",
        MESSAGE: (
          <p>
            Team member{" "}
            <span className="text-primary text-semibold">{email}</span> invited
            successfully.
            <br />
            You may wish to notify your invitee, as invitation emails may land in spam and are unnoticed.
          </p>
        ),
      }),
      DELETE: ({ email }: { email: string }) => ({
        TITLE: "Success!",
        MESSAGE: (
          <span>
            Team member{" "}
            <span className="text-primary text-semibold">{email}</span> deleted
            successfully.
          </span>
        ),
      }),
      DELETE_CONFIRMATION: ({ email }: { email: string }) => ({
        TITLE: "Delete Team Member!",
        MESSAGE: (
          <span>
            Are you sure you want to delete{" "}
            <span className="text-primary text-semibold">{email}</span>?
          </span>
        ),
      }),
    },
  },
  GOOGLE_ANALYTICS_WARNING: {
    TITLE: "Google Analytics!",
    MESSAGE: (
      <span>
        You will get an email notification by{" "}
        <span className="text-primary text-semibold">
          Google (example below)
        </span>{" "}
        because you allowed AdScore to access Google on your behalf. This is a
        standard process to ensure that no un-intended access is given.
      </span>
    ),
  },
};
