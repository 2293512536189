import { ElementType, ReactNode, Suspense } from "react";
import Loader from "@/components/atoms/Loader/Loader";

const Loadable = (Component: ElementType): (props: any) => ReactNode => (props: any) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
