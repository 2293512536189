/** API Configurations.. */
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const CHATBOT_API_BASE_URL = import.meta.env.VITE_API_BASE_URL + "/insight-flow";
// export const INSIGHT_TUNE_API_BASE_URL = import.meta.env.VITE_INSIGHT_TUNE_API_BASE_URL;

// Insight tune URL with api gateway of backend..
export const INSIGHT_TUNE_API_BASE_URL = import.meta.env.VITE_API_BASE_URL + "/insight-tune";

export const AUTH0_AUDIENCE = API_BASE_URL;
export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_CALLBACK_URL = import.meta.env.VITE_AUTH0_CALLBACK_URL;
export const APP_URL = import.meta.env.VITE_URL || "";
/** Sentry environment variables.. */
export const SENTRY_SDN = import.meta.env.VITE_SENTRY_SDN;
export const APP_ENV = import.meta.env.VITE_ENV;
/** MUI license key */
export const MUI_LICENSE_KEY = import.meta.env.VITE_MUI_LICENSE_KEY || "";
