import { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { NotificationContext } from "@/providers/NotificationProvider";
import { LoadingButton } from "@mui/lab";

function NotificationComponent() {
  const { notification, notificationLoading, removeNotification } =
    useContext(NotificationContext);
  const {
    title,
    message,
    type = "success",
    onConfirm,
    onClose,
    confirmButtonText,
    titleStyle = {},
    messageStyle = {},
    enableConfirmButton = true
  } = notification || {};
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(enableConfirmButton);

  if (!notification) {
    return null;
  }

  const handleClose = () => {
    removeNotification();
    onClose?.();
  };
  const handleConfirm = () => {
    onConfirm?.();
  };

  /** Color mapper to display on title.. */
  const titleColorMapper: Record<string, string> = {
    success: "primary",
    error: "primary",
    alert: "primary",
    confirm: "primary",
  };

  const borderColorMapper: Record<string, string> = {
    success: "#345792",
    error: "#345792",
    alert: "#345792",
    confirm: "#345792",
  };

  return (
    <Dialog
      open={true}
      translate="yes"
      onClose={handleClose}
      data-cy="notification-message-modal"
      PaperProps={{
        style: {
          border: 3,
          borderColor: borderColorMapper[type],
          borderStyle: "solid",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle
        data-cy="notification-message-modal-title"
        variant="h3"
        sx={{
          color: titleColorMapper[type] || "primary",
          ...titleStyle,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent data-cy="notification-message-modal-content">
        <DialogContentText
          component={"div"}
          sx={{
            fontSize: 18,
            textAlign: "center",
            color: "#000",
            ...messageStyle,
          }}
        >
          {typeof message === "function" ? message((confirmBtnDisabled) => {
            setConfirmButtonDisabled(confirmBtnDisabled);
          }) : message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        data-cy="notification-message-modal-actions"
        sx={{
          p: 2,
          ...(type !== "confirm"
            ? {
                display: "flex",
                alignItems: "center !important",
                justifyContent: "center !important",
              }
            : {}),
        }}
      >
        {type === "confirm" && <Button onClick={handleClose}>Cancel</Button>}
        {type === "confirm" && (
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            loading={notificationLoading}
            loadingPosition="center"
            disabled={!confirmButtonDisabled}
          >
            {confirmButtonText || "Confirm"}
          </LoadingButton>
        )}
        {type === "alert" && (
          <Button variant="contained" color="primary" onClick={handleClose}>
            Ok
          </Button>
        )}
        {type === "success" && (
          <Button variant="contained" color="primary" onClick={handleClose}>
            Ok
          </Button>
        )}
        {type === "error" && (
          <Button variant="contained" color="primary" onClick={handleClose}>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default NotificationComponent;
