import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./baseQuery";
import { API_BASE_URL } from "@/config/env.config";

const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_BASE_URL || "" }),
  tagTypes: [
    "scoreAds",
    "scoreAdSets",
    "AccountState",
    "ApplicationFeatures",
    "Stores",
    "scoreCardDetails",
    "storeCard",
    "adStats",
    "scoreAdSetsLast7DaysHistory",
    "scoreAdsLast7DaysHistory",
    "storeStats",
  ],
  endpoints: () => ({}),
});

export default baseApi;