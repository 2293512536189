export const USER_EMAIL_KEY = "userEmail";
export const DEMO_STORE_NAME = "DEMO_STORE";
export const CONNECTORS = {
  FACEBOOK: "fb",
  GOOGLE_ANALYTICS: "ga",
  SHOPIFY: "shopify",
};
export const INTERNAL_SERVER_STORAGE_ERROR = "err_500";
export const DEMO_CREDENTIALS = {
  email: "demo@myadscore.com",
  password: "Demo@123",
};
