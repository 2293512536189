// function to save email to storage

import { setSentryUser } from "@/services/sentry.service";

/**
 *
 * @param {string} email
 */
export const setUserEmail = (email: string) => {
  // Set user email in _mtm_ window object..
  (window as any)._mtm = (window as any)._mtm || [];
  (window as any)._mtm.push({ event: "login", "user-id": email });
  // Commented out and may be revealed as per need, later..
  // setStorage(USER_EMAIL_KEY, email);
};

/** Get email from the storage.. */
export const getUserEmail = () => {
  (window as any)._mtm = (window as any)._mtm || [];
  return (window as any)._mtm.find((e: any) => e["event"] === "login");
  // Commented out and may be revealed as per need, later..
  // return getStorage(USER_EMAIL_KEY);
};

/** Remove email from the storage */
export const removeUserEmail = () => {
  /**
   * After logout we are removing
   * 1. MTM parameter.
   * 2. Sentry User.
   */
  (window as any)._mtm = (window as any)._mtm || [];
  (window as any)._mtm = [];
  setSentryUser({
    email: undefined,
  });
  // Commented out and may be revealed as per need, later..
  // removeStorage(USER_EMAIL_KEY);
};

export const validateEmailExists = (getIdTokenClaims: any) => {
  // Validate the email if exists and not empty.
  /**
   * Set Email for MTM Parameter..
   * Set Email for Sentry..
   */
  getIdTokenClaims().then((e: any) => {
    if (e) {
      const userEmail = getUserEmail();
      if (!userEmail) {
        setUserEmail(e.email);
      }
      setSentryUser({
        email: e.email,
      });
    }
  });
};
