import Loader from "@/components/atoms/Loader/Loader";
import Auth0CallbackError from "@/components/organisms/Auth0CallbackError";
import { useAuth0 } from "@auth0/auth0-react";

const Auth0Callback = () => {
  const { isLoading, error } = useAuth0();
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    if (error.message === "email_not_verified") {
      return (
        <Auth0CallbackError
          title="Email not verified!"
          message={
            "Verification email is sent to you, kindly verify your email."
          }
        />
      );
    }
    return (
      <Auth0CallbackError
        title="Authentication failed"
        message={error.message}
      />
    );
  }
  return <></>
};

export default Auth0Callback;
