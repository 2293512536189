export const STORE_FILTERS = {
  AGGREGATION: [
    {
      name: "All ads",
      value: "StoreDate",
      allowCountryFilter: false,
    },
    {
      name: " Ads per country",
      value: "StoreCountryDate",
      allowCountryFilter: true,
    },
  ],
} as const;

export const STORE_FILTERS_ADDASH = {
  AGGREGATION: [
    ...STORE_FILTERS.AGGREGATION,
    {
      name: "All ad sets",
      value: "Adset_StoreDate",
      allowCountryFilter: false,
    },
  ]
} as const;

export type Aggregation = "StoreDate" | "StoreCountryDate" | "Adset_StoreDate";