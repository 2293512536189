import axios, { AxiosInstance } from "axios";
import { API_BASE_URL } from "@/config/env.config";

const AXIOS_TIMEOUT = 30000;

export default class ApiService {
  axios: AxiosInstance;
  access_token: string = "";
  accessTokenSet: boolean = false;
  axios_timeout = AXIOS_TIMEOUT;

  constructor(accessToken: string = "") {
    this.access_token = accessToken;
    this.axios = this.createInstance(accessToken);
  }

  setAccessToken(accessToken: string) {
    if (this.accessTokenSet) {
      return;
    }

    this.access_token = accessToken;
    this.axios = this.createInstance(accessToken);
  }
  
  setTimetout(timeout: number) {
    this.axios_timeout = timeout;
    this.axios = this.createInstance(this.access_token);
  }

  private createInstance(accessToken?: string) {
    if (accessToken) {
      this.accessTokenSet = true;
    }

    return axios.create({
      baseURL: API_BASE_URL,
      timeout: this.axios_timeout,
      headers: {
        "Content-Type": "application/json",
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
    })
  }
}
