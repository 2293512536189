import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "@/components/atoms/Loader/Loader";
import { validateEmailExists } from "@/utils/auth.util";
import { Navigate } from "react-router";

const AuthenticationGuard = ({
  component,
  isRouteAllowed = true,
}: {
  component: any;
  isRouteAllowed?: boolean;
}) => {
  const { getIdTokenClaims } = useAuth0();

  if (!isRouteAllowed) {
    return <Navigate to={"/"} />;
  }

  // Check if the email exists if not then save
  // it to storage.
  validateEmailExists(getIdTokenClaims);
  // ##########################################

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });

  return <Component />;
};

export default AuthenticationGuard;
