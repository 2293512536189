import { MESSAGES } from "@/config/dialogMessages.config";
import { INotification } from "@/providers/NotificationProvider/type";
import { ReactNode } from "react";
import { getStorage, setStorage } from "./storage.util";
import { INTERNAL_SERVER_STORAGE_ERROR } from "@/config/global.config";
import { AxiosError } from "axios";

type ErrorStatusMapper = { [key: number | string]: string | ReactNode };

export const handleErrorMessage = (
  error: any,
  statusMapper: ErrorStatusMapper,
): string | ReactNode => {
  const status = error?.response?.status;
  if (statusMapper?.[status]) {
    return statusMapper?.[status];
  }
  return MESSAGES.GENERAL_ERROR_MESSAGE;
};

export const defaultErrorNotification = ({
  addNotification,
  onClose = () => {}
}: {
  addNotification: (props: INotification) => void;
  onClose?: () => void;
}) => {
  addNotification({
    title: "Oops!",
    message: MESSAGES.GENERAL_ERROR_MESSAGE,
    type: "error",
    onClose,
  });
};

export const handleInternalServerError = ({
  addNotification,
  error
}: {
  addNotification: (props: INotification) => void;
  error: AxiosError;
}) => {
  const status = error?.response?.status as number;

  if (status !== 500) {
    return;
  }

  let errorMessage = MESSAGES.GENERAL_ERROR_MESSAGE_REFRESH;
  const err_500_seen = getStorage(INTERNAL_SERVER_STORAGE_ERROR);

  if (err_500_seen) {
    errorMessage = MESSAGES.GENERAL_ERROR_MESSAGE;
  } else {
    setStorage(INTERNAL_SERVER_STORAGE_ERROR, "true");
  }

  addNotification({
    title: "Oops!",
    message: handleErrorMessage(error, {
      500: errorMessage,
    }),
    type: "error",
  });
}