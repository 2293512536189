import { useContext } from "react";
import { NotificationContext } from "@/providers/NotificationProvider";

function useNotification() {
  const {
    notification,
    addNotification,
    notificationLoading,
    setNotificationLoading,
    removeNotification,
  } = useContext(NotificationContext);

  return {
    notification,
    addNotification,
    removeNotification,
    notificationLoading,
    setNotificationLoading,
  };
}

export default useNotification;
