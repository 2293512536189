import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "@/config/redux.config";
import Logo from "@/components/atoms/Logo";

// ==============================|| MAIN LOGO ||============================== //

const MainLogo = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <Logo />
  </ButtonBase>
);

export default MainLogo;
