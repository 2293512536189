import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import PageAlert from "@/components/molecules/PageAlert/PageAlert";

interface Auth0CallbackErrorProps {
  message?: string;
  title?: string;
}

export default function Auth0CallbackError({
  title,
  message,
}: Auth0CallbackErrorProps) {
  const { logout } = useAuth0();
  const handleLogoutAndBack = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin + "/home",
      },
    });
  };
  return (
    <PageAlert
      title={title}
      message={message}
      showGoBackButton={true}
      isAlert={true}
      onGoBack={handleLogoutAndBack}
      goBackText="Home"
    />
  );
}

Auth0CallbackError.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
};
