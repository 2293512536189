import baseApi from "@/store/baseAPI";
import type { IStoreConfiguration } from "@/models/storeConfiguration";
import type { IStore, IApplicationFeatures } from "@/models/store";

export const storeApi = baseApi.injectEndpoints({
  endpoints: builder => {
    return {
      getStoreState: builder.query<IStoreConfiguration, { access_token: string; store: string; }>({
        query: ({ store, access_token }) => ({
          url: '/api/AccountState',
          method: "GET",
          access_token,
          params: {
            Account: store,
          },
        }),
        providesTags: ["AccountState"],
        keepUnusedDataFor: 21600,
      }),
      getStores: builder.query<IStore[], { access_token: string; }>({
        query: ({ access_token }) => ({
          url: '/api/Account',
          method: "GET",
          access_token,
        }),
        providesTags: ["Stores"],
        keepUnusedDataFor: 21600,
      }),
      getApplicationFeatures: builder.query<IApplicationFeatures, { access_token: string; }>({
        query: ({ access_token }) => ({
          url: '/api/ApplicationFeatures',
          method: "GET",
          access_token,
        }),
        providesTags: ["Stores"],
        keepUnusedDataFor: 21600,
      }),
      deleteStore: builder.query<void, { access_token: string; account: string; }>({
        query: ({ access_token, account }) => ({
          url: '/api/AccountSignOut',
          method: "GET",
          access_token,
          params: {
            account,
          },
        }),
      }),
      addStore: builder.query<IStore, { access_token: string; store: string; code: string; }>({
        query: ({ store, access_token, code }) => ({
          url: `/api/AccountSignin?account=${store}&code=${code}`,
          method: "GET",
          access_token,
        }),
      }),
    };
  },
});

export const {
  useGetStoreStateQuery,
  useGetStoresQuery,
  useGetApplicationFeaturesQuery,
  useAddStoreQuery,
  useDeleteStoreQuery,
} = storeApi;
