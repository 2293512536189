import React, { useState, useCallback, ReactNode } from "react";
import { INotification, NotificationContextType } from "./type";

export const NotificationContext = React.createContext<NotificationContextType>(
  {
    notification: null,
    notificationLoading: false,
    setNotificationLoading: () => {},
    addNotification: () => {},
    removeNotification: () => {},
  }
);

interface NotificationProviderProps {
  children: ReactNode;
}

export default function NotificationProvider({
  children,
}: NotificationProviderProps) {
  const [notification, setNotification] = useState<INotification | null>(null);
  const [notificationLoading, setNotificationLoading] =
    useState<boolean>(false);

  const removeNotification = () => {
    setNotificationLoading(false);
    setNotification(null);
  };

  const addNotification = (props: INotification) => {
    setNotification(props);
  };

  const contextValue: NotificationContextType = {
    notification,
    notificationLoading,
    setNotificationLoading,
    addNotification: useCallback(
      (props: INotification) => addNotification(props),
      []
    ),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}
