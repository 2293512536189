import "./style.css";
import { IconExclamationCircle } from "@tabler/icons-react";

export default function AlertAnimated() {
  return (
    <div className="wrapper">
      <IconExclamationCircle
        style={{
          width: "60%",
          height: "60%",
          color: "orange",
        }}
      />
    </div>
  );
}
