import { IStore } from "@/models/store";
import { AdData } from "@/shared/type";
import { getStorage, setStorage } from "./storage.util";
import { IStoreConfiguration } from "@/models/storeConfiguration";

interface Store {
  name: string;
  id: string;
  // Add any other properties if needed
}

interface SelectStoreBySearchParamProps {
  preSelectedStoreInRedux: string | null;
  storeList: Store[];
  setSearchParams: (searchParams: any) => void;
  searchParams: any;
}

export function selectStoreBySearchParam({
  preSelectedStoreInRedux,
  storeList,
  setSearchParams,
  searchParams,
}: SelectStoreBySearchParamProps): Store | false {
  if (!storeList?.[0]) return false;

  const storeNameParam = searchParams.get("account");
  if (storeNameParam) {
    const store = storeList.find(
      (store) => store.id?.toLowerCase() === storeNameParam.toLowerCase()
    );
    if (store) return store;
  }

  searchParams.set(
    "account",
    preSelectedStoreInRedux?.toLowerCase() || storeList[0].id.toLowerCase()
  );
  setSearchParams(searchParams);

  return false;
}

export function getStoreFromQueryParam() {
  const storeName = new URLSearchParams(window.location.search).get("account");
  return storeName || window.location.pathname;
}

export function sortByScore(data: AdData[]): AdData[] {
  return (data || []).sort(
    (a: AdData, b: AdData) => b.adScore[0] - a.adScore[0]
  ) as AdData[];
}

export function getStoreNameFromStoresById(
  storeId: string,
  stores: IStore[]
): string {
  if (!stores.length || !storeId) {
    return "";
  }

  return stores.find((store) => store.id === storeId)?.name ?? storeId;
}

export function getStoreFromLocalStorage(): IStoreConfiguration {
  const store = getStorage("ACCOUNT_STORE");
  return store ? JSON.parse(store) : {};
}

export function setStoreToLocalStorage(store: IStoreConfiguration) {
  setStorage("ACCOUNT_STORE", JSON.stringify(store));
}
