import { useCallback, useEffect } from "react";
import { useAuthentication } from "./authentication/useAuthentication";
import { SENTRY_TAG_NAME } from "@/config/sentry.config";
import { storeApi } from "@/store/api/store";
import { useFetch } from "./useFetch";
import { IApplicationFeatures } from "@/models/store";
// import { AxiosError } from "axios";

export function useApplicationFeatures() {
  const { isAuthenticated, accessToken } = useAuthentication();
  const [getApplicationFeatures] = storeApi.endpoints.getApplicationFeatures.useLazyQuery();
  const { fetcher, data, isLoading } = useFetch<IApplicationFeatures>({
    initialState: {
      StoreCreationCode: false,
    },
    deps: [accessToken, isAuthenticated],
    sentryTag: {
      name: SENTRY_TAG_NAME.API_NAME,
      value: "Application Feature API",
    },
  });

  const refetch = useCallback(async () => {
    if (!isAuthenticated || !accessToken) {
      return;
    }

    fetcher(() => getApplicationFeatures({ access_token: accessToken }).unwrap());
    // fetcher(() => {throw new AxiosError("fake error", "500", undefined, undefined, {
    //   data: {},
    //   status: 500,
    //   statusText: "Internal Server Error",
    //   headers: {},
    //   // @ts-ignore
    //   config: { headers: { } },
    // })});
  }, [accessToken, isAuthenticated]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    data,
    isLoading,
    refetch,
  };
}
