import { DEMO_CREDENTIALS } from "@/config/global.config";
import { useAuth0 } from "@auth0/auth0-react";
import Add from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import type { SelectChangeEvent } from "@mui/material/Select";

export interface IStoreDropdownProps {
  storeNames: [string, string][];
  selectedStore: string;
  onChange: (e: SelectChangeEvent<any>) => void;
  isLoading?: boolean;
}

export function StoreDropdown({
  storeNames,
  selectedStore,
  onChange,
  isLoading = false,
}: IStoreDropdownProps) {
  const authPayload = useAuth0();
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="store-select-label">Data model</InputLabel>
      <Select
        labelId="store-select-label"
        id="store-select"
        data-cy="store-select"
        value={selectedStore ? selectedStore.toLowerCase() : ""}
        label="Data model"
        placeholder="Data model"
        onChange={onChange}
        disabled={isLoading}
      >
        {storeNames.length &&
          storeNames[0].length &&
          storeNames.map(([id, name]) => (
            <MenuItem
              key={id}
              value={id.toLowerCase()}
              sx={{ pl: 6.5 }}
              data-cy="store-select-item"
            >
              <ListItemText
                id={
                  id.toLowerCase() === selectedStore.toLowerCase()
                    ? "selected-store"
                    : id.toLowerCase()
                }
                primary={name}
              />
            </MenuItem>
          ))}
        {authPayload?.user?.email !== DEMO_CREDENTIALS.email && (
          <MenuItem value="newStore" data-cy="store-add-new-store">
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText id="new-store" primary="New data model" />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
