import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPrompt, IUpdatePromptConfigurationParams } from "@/models/prompt";

const initialState = {
  promptList: null as IPrompt[] | null,
  promptStoreName: null as string | null,
  selectedPrompt: null as IPrompt | null,
  promptConfiguration: null as IUpdatePromptConfigurationParams | null,
};

const promptSlices = createSlice({
  name: "prompt",
  initialState,
  reducers: {
    setPromptList: (state, action: PayloadAction<IPrompt[]>) => {
      state.promptList = action.payload;
    },
    setSelectedPrompt: (state, action: PayloadAction<IPrompt | null>) => {
      state.selectedPrompt = action.payload;
    },

    setPromptConfiguration: (
      state,
      action: PayloadAction<IUpdatePromptConfigurationParams | null>
    ) => {
      state.promptConfiguration = action.payload;
    },
    setPromptStoreName: (state, action: PayloadAction<string | null>) => {
      state.promptStoreName = action.payload;
    },
  },
});

export const {
  setSelectedPrompt,
  setPromptList,
  setPromptConfiguration,
  setPromptStoreName,
} = promptSlices.actions;

export default promptSlices.reducer;
