import { useRoutes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { IStorePermissions } from "@/models/storeConfiguration";
import useFetchAccountState from "@/hooks/store-management/useFetchAccountState";

export default function Routes() {
  const { accountState } = useFetchAccountState({
    fetchOnlyStore: true,
  });

  return useRoutes(
    AppRoutes({
      storeName: accountState?.name,
      permissions: accountState?.permissions || ({} as IStorePermissions),
    })
  );
}
