import { useCallback, useState } from "react";
import { useAuthentication } from "./authentication/useAuthentication";
import { sentryCaptureException } from "@/services/sentry.service";
import useNotification from "./useNotification";
import { handleInternalServerError } from "@/utils/errorHandler";
import { removeStorage } from "@/utils/storage.util";
import { INTERNAL_SERVER_STORAGE_ERROR } from "@/config/global.config";
import { AxiosError } from "axios";

interface Props<T extends unknown> {
  initialState: T;
  deps: unknown[];
  sentryTag: {
    name: string | null;
    value: string | null;
  }
}

export function useFetch<T extends unknown>({ initialState, deps = [], sentryTag }: Props<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T>(initialState);
  const [errorResponse, setErrorResponse] = useState<any>({});
  const { addNotification, removeNotification } = useNotification();
  const { isAuthenticated, accessToken } = useAuthentication();
  const fetcher = useCallback(async (_fetcher: () => Promise<T>) => {
    if (!isAuthenticated || !accessToken) {
      removeNotification();
      return;
    }

    try {
      setIsLoading(true);
      
      const data = await _fetcher();

      setData(data);
      removeStorage(INTERNAL_SERVER_STORAGE_ERROR);
    } catch (err) {
      const error = err as AxiosError;

      setErrorResponse(error);
      handleInternalServerError({ addNotification, error });
      sentryCaptureException({
        tag: sentryTag,
        error,
      });
    } finally {
      setIsLoading(false);
    }
  }, deps);

  return {
    data,
    isLoading,
    fetcher,
    errorResponse,
  };
}
