import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./baseQuery";
import { INSIGHT_TUNE_API_BASE_URL } from "@/config/env.config";

const INSIGHT_TUNE_API_TIMEOUT = 120000;

const baseInsightTuneApi = createApi({
  reducerPath: "baseInsightTuneApi",
  baseQuery: axiosBaseQuery({ baseUrl: `${INSIGHT_TUNE_API_BASE_URL || ""}/v1`, timeout: INSIGHT_TUNE_API_TIMEOUT}),
  tagTypes: [
    "insight_tune"
  ],
  endpoints: () => ({}),
});

export default baseInsightTuneApi;