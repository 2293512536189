import Typography from "@mui/material/Typography";
import type { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import type { ColumnsDefinition, } from "@/pages/dashboard/default/columns";
import { STORE_FILTERS } from "@/config/store.config";
import ScoreChart from "@/components/molecules/Charts/ScoreCharts";

export const AdSpendColumns = ({ aggregation }: { aggregation: string }) => {
  const cols: ColumnsDefinition[] = [
  {
    field: "adSetName",
    headerName: "AdSet Name",
    width: 200,
    visible: true,
  },
  {
    field: "budget",
    headerName: "Daily Budget",
    type: 'number',
    groupable: false,
    width: 100,
    visible: true,
  },
  {
    field: "budgetKey",
    headerName: "Budget Key",
    width: 100,
    renderCell: (params: any) => {
      return (
        <Typography variant="inherit" sx={{ marginLeft: 2 }}>
          {params.value?.slice(0, 3)}
        </Typography>
      );
    },
    visible: true,
  }];

  if (aggregation === STORE_FILTERS.AGGREGATION[1].value) {
    cols.push({
      field: "country",
      headerName: "Country",
      width: 150,
      visible: true,
    });
  }
  
  cols.push(
    {
      field: "budgetRecommendation",
      headerName: "Recommended Budget",
      type: 'number',
      width: 150,
      visible: true,
    },
    {
      field: "adSetCampaignLevelScoreSpendEfficiency",
      headerName: "Score Spend Efficiency",
      type: 'number',
      width: 150,
      visible: true,
    },
    {
      field: "adSetCampaignLevelScoreSpendEfficiencyAverage",
      headerName: "SSE 7d average",
      renderHeader: () => {
        return (
          <Typography component={"div"} variant="inherit" sx={{ textAlign: "center", lineHeight: "20px" }}>
            <Typography component={"p"} variant="inherit" fontWeight={500}>Score Spend Efficiency</Typography>
            <Typography component={"p"} variant="inherit" fontWeight={500}>7d Average</Typography>
          </Typography>
        );
      },
      type: 'number',
      width: 200,
      visible: true,
    },
    {
      field: "adSetCampaignLevelScoreSpendEfficiencyLast7Days",
      headerName: "7d history",
      renderCell: params => {
          if (!params.value || !params.value?.length) {
            return "";
          }

          // const firstAdDayData = params.value.map((value: any, idx: number) => ({
          //   ...value,
          //   Status: idx === 6 ? "ACTIVE" : "NOT FOUND",
          //   ScoreSpendEfficiency: idx === 6 ? 23.4 : null,
          // }))
          // const fourAdDaysData = params.value.map((value: any, idx: number) => ({
          //   ...value,
          //   Status: idx === 6 || idx === 5|| idx === 4|| idx === 3 ? value.Status : "NOT FOUND",
          //   ScoreSpendEfficiency: idx === 6 || idx === 5|| idx === 4|| idx === 3 ? value.ScoreSpendEfficiency : null,
          // }))

          return <ScoreChart
            data={params.value}
            // data={fourAdDaysData}
            invertX={false}
            width={"100%"}
            height={"100%"}
          />
      },
      width: 200,
      visible: true,
    },
    {
      field: "adSetCampaignLevelScoreSpendEfficiencyChangeInPercent",
      headerName: "% change",
      renderCell: params => {
        if (!params.value) {
          return "";
        }

        return `${params.value}%`;
      },
      width: 100,
      visible: true,
    }
  );

  const visibleColumns: GridColDef<any>[] = cols.map(({ visible, ...rest }) => rest);
  const columnsVisibility = cols.reduce((acc, column) => {
    acc[column.field] = column.visible;

    return acc;
  }, {} as GridColumnVisibilityModel);

  return {
    visibleColumns,
    columnsVisibility,
  };
};
