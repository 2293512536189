import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IStore } from "@/models/store";
import type { IStoreConfiguration } from "@/models/storeConfiguration";

const initialState = {
  storesList: [] as IStore[],
  storeLoaded: false,
  selectedAccount: "",
  accountState: {} as IStoreConfiguration,
};

const storeSlice = createSlice({
  name: "local_store",
  initialState,
  reducers: {
    setSelectedStores: (state, action: PayloadAction<IStore[]>) => {
      state.storesList = action.payload;
    },
    setSelectedStoreLoaded: (state, action: PayloadAction<boolean>) => {
      state.storeLoaded = action.payload;
    },
    setSelectedAccount: (state, action: PayloadAction<string>) => {
      state.selectedAccount = action.payload;
    },
    setSelectedAccountState: (state, action: PayloadAction<IStoreConfiguration>) => {
      state.accountState = action.payload;
    },
  },
});

export const {
  setSelectedStoreLoaded,
  setSelectedStores,
  setSelectedAccount,
  setSelectedAccountState,
} = storeSlice.actions;

export default storeSlice.reducer;
