import { useAuth0 } from "@auth0/auth0-react";
import useNotification from "@/hooks/useNotification";
import { removeUserEmail } from "@/utils/auth.util";

/**
 * Logout utility hook
 */
export default function useLogout() {
  const { logout } = useAuth0();
  const { addNotification, removeNotification } = useNotification();

  const handleLogout = async () => {
    addNotification({
      title: "Logout!",
      message: `Are you sure you want to logout?`,
      type: "confirm",
      confirmButtonText: "Ok",
      onConfirm: async () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin + "/home",
          },
        });
        /** Remove email from storage */
        removeUserEmail();
        removeNotification();
      },
    });
  };

  return {
    handleLogout,
  };
}
