import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../authentication/useAuthentication";
import { IStoreFBConfig, IStoreGAConfig } from "@/models/storeConfiguration";
import { sentryCaptureException } from "@/services/sentry.service";
import { SENTRY_TAG_NAME } from "@/config/sentry.config";
import { useAppDispatch, useAppSelector } from "@/store";
import { storeApi } from "@/store/api/store";
import { setSelectedAccountState } from "@/store/slices/store";
// import { handleInternalServerError } from "@/utils/errorHandler";
// import useNotification from "../useNotification";
import { removeStorage } from "@/utils/storage.util";
import { INTERNAL_SERVER_STORAGE_ERROR } from "@/config/global.config";
import { setStoreToLocalStorage } from "@/utils/store.util";
// import { AxiosError } from "axios";

/**
 * Account state utility hook
 * It will fetch data from the account state API..
 */
export default function useFetchAccountState({
  fetchOnlyStore = true,
  selectedAccount,
}: {
  fetchOnlyStore?: boolean;
  selectedAccount?: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const accountState = useAppSelector((state) => state.store.accountState);
  const dispatch = useAppDispatch();
  const [fbVariables, setFbVariables] = useState<IStoreFBConfig>({
    fB_ClientID: "",
    fB_RedirectURL: "",
    fB_Scope: "",
    fB_State: "",
  });
  const [gaVariables, setGaVariables] = useState<IStoreGAConfig>({
    gA_ClientID: "",
    gA_RedirectURL: "",
    gA_Scope: "",
    gA_State: "",
    sF_State: "",
  });
  const { accessToken, isAuthenticated } = useAuthentication();
  const [getStoreState] = storeApi.endpoints.getStoreState.useLazyQuery();
  // const { addNotification } = useNotification();

  useEffect(() => {
    if (!accountState) return;

    setFbVariables({
      fB_ClientID: accountState.fB_ClientID,
      fB_RedirectURL: accountState.fB_RedirectURL,
      fB_Scope: accountState.fB_Scope,
      fB_State: accountState.fB_State,
    });

    setGaVariables({
      gA_ClientID: accountState.gA_ClientID,
      gA_RedirectURL: accountState.gA_RedirectURL,
      gA_Scope: accountState.gA_Scope,
      gA_State: accountState.gA_State,
      sF_State: accountState.sF_State,
    });
  }, [accountState]);

  const fetchAccountState = useCallback(async () => {
    try {
      if (
        fetchOnlyStore ||
        !accessToken ||
        !selectedAccount ||
        (Object.keys(accountState).length > 0 &&
          accountState.name.toLowerCase() === selectedAccount.toLowerCase())
      ) {
        return;
      }

      setIsLoading(true);
      const data = await getStoreState({
        store: selectedAccount,
        access_token: accessToken,
      }).unwrap();

      removeStorage(INTERNAL_SERVER_STORAGE_ERROR);
      setStoreToLocalStorage(data);
      dispatch(setSelectedAccountState(data));
    } catch (err) {
      // const error = err as AxiosError;

      // handleInternalServerError({ addNotification, error });
      sentryCaptureException({
        tag: {
          name: SENTRY_TAG_NAME.API_NAME,
          value: "Fetch Account State",
        },
        error: err,
      });
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, accountState, dispatch, fetchOnlyStore, selectedAccount]);

  useEffect(() => {
    if (fetchOnlyStore) return;

    if (isAuthenticated && selectedAccount) {
      fetchAccountState();
    }
  }, [fetchAccountState, fetchOnlyStore, isAuthenticated, selectedAccount]);

  return {
    accountState,
    fbVariables,
    gaVariables,
    isFBConnected: accountState ? accountState?.fB_State === "Connected" : null,
    isGAConnected: accountState ? accountState?.gA_State === "Connected" : null,
    isShopifyConnected: accountState
      ? accountState?.sF_State === "Connected"
      : null,
    canConnectFacebook: accountState
      ? accountState?.permissions?.canConnectFacebook
      : false,
    canConnectGoogleAnalytics: accountState
      ? accountState?.permissions?.canConnectGoogleAnalytics
      : false,
    canConnectShopify: accountState
      ? accountState?.permissions?.canConnectShopify
      : false,
    canDelete: accountState ? accountState?.permissions?.canDelete : false,
    refetch: fetchAccountState,
    isLoading,
  };
}
