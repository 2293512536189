import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import baseApi from "@/store/baseAPI";
import filterSlice from "./slices/filters";
import storeSlice from "./slices/store";
import promptSlice from './slices/prompt';
import baseChatbotApi from "./baseChatbotAPI";
import baseInsightTuneApi from "./baseInsightTuneAPI";

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [baseChatbotApi.reducerPath]: baseChatbotApi.reducer,
    [baseInsightTuneApi.reducerPath]: baseInsightTuneApi.reducer,
    filters: filterSlice,
    store: storeSlice,
    prompt: promptSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      baseApi.middleware,
      baseChatbotApi.middleware,
      baseInsightTuneApi.middleware,
    ]),
});

type Store = typeof store;
type AppDispatch = Store["dispatch"];
type RootState = ReturnType<Store["getState"]>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
