import { STORE_FILTERS } from "@/config/store.config";
import type { Aggregation } from "@/config/store.config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { columnsVisibility as adDashColumnsVisibility } from "@/pages/dashboard/default/columns";
import type { GridColumnVisibilityModel } from "@mui/x-data-grid-premium";

const startDate = dayjs().subtract(1, "day");
const initialState = {
  aggregation: STORE_FILTERS.AGGREGATION[0].value as Aggregation,
  selectedCountry: "all",
  selectedCampaigns: [] as string[],
  selectedAdSets: [] as string[],
  onlyAdsWithScore: false,
  dateRange: {
    startDate: startDate,
    endDate: startDate,
    tmpStartDate: startDate,
    tmpEndDate: startDate,
  },
  isFormatSetting: false,
  columnsVisibility: {
    adDash: adDashColumnsVisibility,
    adSpend: {} as GridColumnVisibilityModel,
  },
  paginationSize: 100,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSelectedAggregation: (state, action: PayloadAction<Aggregation>) => {
      state.aggregation = action.payload;
    },
    setSelectedCountry: (state, action: PayloadAction<string>) => {
      state.selectedCountry = action.payload;
    },
    setSelectedCampaigns: (state, action: PayloadAction<string[]>) => {
      state.selectedCampaigns = action.payload;
    },
    setSelectedAdSets: (state, action: PayloadAction<string[]>) => {
      state.selectedAdSets = action.payload;
    },
    setSelectedAdsWithScore: (state, action: PayloadAction<boolean>) => {
      state.onlyAdsWithScore = action.payload;
    },
    setSelectedDateRange: (state, action: PayloadAction<Dayjs>) => {
      state.dateRange.startDate = action.payload;
      state.dateRange.tmpStartDate = action.payload
    },
    setSelectedDateRangeEnd: (state, action: PayloadAction<Dayjs>) => {
      state.dateRange.endDate = action.payload;
    },
    setSelectedDateRangeTmpStart: (state, action: PayloadAction<Dayjs>) => {
      state.dateRange.tmpStartDate = action.payload;
    },
    setSelectedDateRangeTmpEnd: (state, action: PayloadAction<Dayjs>) => {
      state.dateRange.tmpEndDate = action.payload;
    },
    setSelectedIsFormatSetting: (state, action: PayloadAction<boolean>) => {
      state.isFormatSetting = action.payload;
    },
    setSelectedScoreAdsColumnsVisibility: (state, action: PayloadAction<GridColumnVisibilityModel>) => {
      state.columnsVisibility.adDash = action.payload;
    },
    setSelectedScoreSetsColumnsVisibility: (state, action: PayloadAction<GridColumnVisibilityModel>) => {
      state.columnsVisibility.adSpend = action.payload;
    },
    setSelectedPageSize: (state, action: PayloadAction<number>) => {
      state.paginationSize = action.payload;
    },
  },
});

export const {
  setSelectedAdSets,
  setSelectedAdsWithScore,
  setSelectedCampaigns,
  setSelectedCountry,
  setSelectedAggregation,
  setSelectedDateRange,
  setSelectedIsFormatSetting,
  setSelectedScoreAdsColumnsVisibility,
  setSelectedScoreSetsColumnsVisibility,
  setSelectedPageSize,
  setSelectedDateRangeEnd,
  setSelectedDateRangeTmpStart,
  setSelectedDateRangeTmpEnd,
} = filtersSlice.actions;

export default filtersSlice.reducer;
