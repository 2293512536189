import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

const Loader = () => (
  <Box sx={{
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }}>
    <Box sx={{
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 1301,
      width: "100%",
    }}>
      <LinearProgress color="primary" />
    </Box>
    <CircularProgress disableShrink />
  </Box>
);

export default Loader;
