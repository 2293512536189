import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as yup from "yup";
import useAddNewStore from "@/hooks/store-management/useAddNewStore";
import { useApplicationFeatures } from "@/hooks/useApplicationFeatures";
import useNotification from "@/hooks/useNotification";
import { useState } from "react";

const validationSchema = ({
  isCodeRequired = false,
}: {
  isCodeRequired: boolean;
}) =>
  yup.object({
    account: yup.string().required("Account is required"),
    code: isCodeRequired
      ? yup.string().required("Code is required")
      : yup.string(),
  });

interface NewStoreProps {
  open: boolean;
  handleNewStore: (account?: string) => void;
}

const btnstyle = { margin: "8px 0" };
export default function NewStoreModal({ open, handleNewStore }: NewStoreProps) {
  const { data: applicationFeatures, isLoading: applicationFeatureLoading } = useApplicationFeatures();
  const { addNotification } = useNotification();
  const { addNewStore, isLoading } = useAddNewStore({});
  const [termsAndConditions, setTermsAndConditions] = useState<"none" | "accepted" | "ignored">("none");
  const formik = useFormik({
    initialValues: {
      account: "",
      code: "",
    },
    validationSchema: validationSchema({
      isCodeRequired: applicationFeatures.StoreCreationCode,
    }),
    onSubmit: async (values) => {
      if (termsAndConditions !== "accepted") {
        setTermsAndConditions("ignored");

        return;
      }

      const account = values.account;

      if (!account || account.length < 1) {
        addNotification({
          title: "Validation Failure",
          message: "Please enter a valid data model name.",
          type: "error",
        });

        return;
      }

      if (account.length > 50) {
        addNotification({
          title: "Validation Failure",
          message: "The data model name is too long, it should be no more than 50 characters.",
          type: "error",
        });

        return;
      }

      const code = values.code;
      const createdStore = await addNewStore({
        storeName: account,
        code,
      });

      if (typeof createdStore === "string") {
        formik.resetForm();
        handleNewStore(createdStore);
        setTermsAndConditions("none");
      }
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        data-cy="add-new-store-modal"
        PaperProps={{
          style: {
            border: 3,
            borderColor: "#345792",
            borderStyle: "solid",
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle fontSize={20}>Create a new data model</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a name for the data model such as 'CompanyNameDWH'. This will help you keep your data sources organized.
          </DialogContentText>
          <Box sx={{ mt: 1 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box mb={2}>
                <TextField
                  data-cy="new_store_name"
                  fullWidth
                  id="account"
                  name="account"
                  label="Data Model Name"
                  placeholder="Data Model Name"
                  value={formik.values.account}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.account && Boolean(formik.errors.account)
                  }
                  helperText={formik.touched.account && formik.errors.account}
                  variant="standard"
                  required
                />
              </Box>

              {applicationFeatureLoading ? (
                <Box mb={2}>
                  <Skeleton variant="rounded" width={"100%"} height={138.44} />
                </Box>
              ) : (
                <>
                  {applicationFeatures.StoreCreationCode ? (
                    <Box bgcolor="primary.light" p={2} borderRadius={3} mb={2}>
                      <Typography>
                        <span className="text-primary font-semibold">
                        InsAIghtflow
                        </span>{" "}
                        requires an invitation for new data model installation
                        temporarily. Please enter your access code or contact{" "}
                        <a href="mailto:customer@myadscore.com">
                          customer@myadscore.com
                        </a>
                        . Thank you!
                      </Typography>
                      <Box mb={2} mt={1}>
                        <TextField
                          data-cy="new_store_code"
                          fullWidth
                          id="code"
                          name="code"
                          label="Access Code"
                          placeholder="Enter your access code here"
                          value={formik.values.code}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.code && Boolean(formik.errors.code)
                          }
                          helperText={formik.touched.code && formik.errors.code}
                          variant="standard"
                          required
                        />
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <Box mb={2}>
                <FormControlLabel
                  control={<Checkbox 
                    size="small"
                    data-cy="new_store_terms_and_conditions"
                    name="termsAndConditions"
                    id="termsAndConditions"
                    color="default"
                    inputProps={{ 'aria-label': 'Accept terms and conditions' }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTermsAndConditions("accepted");
                      } else {
                        setTermsAndConditions("none");
                      }
                    }}
                  />}
                  label={
                    <>Accept our {" "}
                      <Typography
                        component={"span"}
                        onClick={() => {
                          setTermsAndConditions("accepted");
                          window.open("https://myadscore.com/en/terms-of-use", "_blank");
                        }}
                        sx={{ textDecoration: "underline" }}
                      >terms and conditions</Typography>
                  </>
                  }
                />
                {termsAndConditions === "ignored" && 
                  (<Box sx={{ color: "#f44336" }}>{"Please accept our terms and conditions prior to creating a new data model"}</Box>)
                }
              </Box>

              <Box
                data-cy="add-new-store-modal-action-buttons"
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="text"
                  sx={{ ml: 2 }}
                  onClick={() => {
                    formik.resetForm();
                    handleNewStore();
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  style={btnstyle}
                  loading={isLoading}
                  disabled={!formik.isValid || applicationFeatureLoading}
                  onClick={() => handleSubmit()}
                >
                  Create Data Model
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
