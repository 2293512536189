import { AUTH0_AUDIENCE } from "@/config/env.config";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

/**
 * Utility hook for authentication..
 * @returns accessToken and isAuthenticated.
 */
export function useAuthentication() {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if(isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: AUTH0_AUDIENCE,
        },
      }).then((token) => {
        setAccessToken(token);
      });
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  return {
    accessToken,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  };
}
