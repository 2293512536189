import { Chart } from 'react-charts';
import type { AxisOptions } from 'react-charts';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import type { AdsetCampaignLevelScoreSpendEfficiencyLast7DaysHistory_JSON } from '@/models/adSpend';

type IScoreData = AdsetCampaignLevelScoreSpendEfficiencyLast7DaysHistory_JSON & { value: number | null; };
interface Props {
  data: AdsetCampaignLevelScoreSpendEfficiencyLast7DaysHistory_JSON[];
  invertX?: boolean;
  width?: string;
  height?: string;
  hardMin?: number;
  hardMax?: number;
  tickCount?: number;
}

function removeDuplicates(data: AdsetCampaignLevelScoreSpendEfficiencyLast7DaysHistory_JSON[]) {
  const seen = new Set();

  return data.filter(item => {
    const duplicate = seen.has(item.Date);

    seen.add(item.Date);

    return !duplicate;
  });
}

export default function ScoreChart({
  data,
  invertX = true,
  width = "100%",
  height = "100%",
  hardMin = 0,
  hardMax = 100,
  tickCount = 3,
} : Props) {
  data = removeDuplicates(data);
  let primaryAxisCounter = 0
  const primaryAxis = useMemo(
    (): AxisOptions<IScoreData> => ({
      getValue: datum => {
        return `${datum.value}xx${primaryAxisCounter++}`;
      },
      elementType: "line",
      scaleType: "band",
      showDatumElements: true,
      formatters: {
        scale(_: number) {
          return " ";
        },
      },
      invert: invertX,
    }),
    []
  );
  const secondaryAxes = useMemo(
    (): AxisOptions<IScoreData>[] => [
      {
        getValue: datum => datum.value,
        elementType: "line",
        scaleType: "linear",
        min: 0,
        hardMin,
        max: 100,
        hardMax,
        tickCount,
        formatters: {
          cursor: (val: number) => {
            if (!val) {
              return;
            }

            const includesDot = val?.toString().substring(0, 2).includes(".");
            const formattedVal = val?.toString().substring(0, includesDot ? 4 : 5);

            if (formattedVal === "0.00") {
              return 0;
            }

            return formattedVal;
          },
          tooltip: (val: number) => val?.toString(),
        },
        showGrid: true,
      },
    ],
    []
  );
  const seenScores = new Map<number, number>();
  const newData = data.map((_data, idx) => {
    if (_data.Status === "NOT FOUND") {
      return { ..._data, value: null };
    }

    let value = _data.Score ?? 0;

    if (seenScores.has(value)) {
      value += (idx / 1000);
      seenScores.set(value, value);
    } else {
      seenScores.set(value, value + (idx / 1000));
    }

    return { ..._data, value };
  });

  return <Box
    sx={{
      width,
      height,
      ".chat-wrapper .axes > g:first-child > g .Axis > .domainAndTicks .tick line": {
        display: "none",
      },
    }}
  >
    <Chart
      className="chat-wrapper"
      style={{ overflow: "hidden" }}
      options={{
        data: [{ data: newData }],
        brush: {},
        // @ts-ignore
        primaryAxis,
        // @ts-ignore
        secondaryAxes,
        showDebugAxes: false,
        showVoronoi: false,
        primaryCursor: false,
        tooltip: false,
      }}
    />
  </Box>
}
