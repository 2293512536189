import { useState } from "react";
import { useTheme } from "@mui/system";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import QueryStats from "@mui/icons-material/QueryStats";
import { useNavigate } from "react-router";
import type { IStorePermissions } from "@/models/storeConfiguration";

export interface IMenuBottomListProps {
  drawerOpen: boolean;
  selectedAccount: string;
  userEmail: string;
  handleLeftDrawerToggle: () => void;
  handleLogout: () => void;
  permissions: IStorePermissions;
}

function isSupportActive() {
  return window.location.pathname.includes("/support") ? true : false;
}
function isSettingsActive() {
  return window.location.pathname.includes("/settings") ? true : false;
}
function isAccountManagementActive() {
  return window.location.pathname.includes("/account-management")
    ? true
    : false;
}
function isRouteActive({ routeName }: { routeName: string }) {
  return window.location.pathname.includes(routeName) ? true : false;
}

export function MenuBottomList({
  drawerOpen,
  selectedAccount,
  userEmail,
  handleLeftDrawerToggle,
  handleLogout,
  permissions,
}: IMenuBottomListProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [openSettings, setOpenSettings] = useState(false);
  const handleClick = () => {
    setOpenSettings((prev) => !prev);
  };

  return (
    <List>
      {permissions?.canAccessAdStats && (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={isRouteActive({
              routeName: "ad-stats",
            })}
            onClick={() => {
              navigate(
                `/ad-stats/?account=${
                  selectedAccount ? selectedAccount.toLowerCase() : null
                }`
              );
            }}
            sx={{
              minHeight: 44,
              justifyContent: drawerOpen ? "initial" : "center",
              px: 2,
              py: 0.5,
              borderRadius: 1.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 1.8 : "auto",
                justifyContent: "center",
              }}
            >
              <Tooltip title="AdStats" placement="right">
                <QueryStats />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              id="ad-stats"
              primary={"AdStats"}
              sx={{ opacity: drawerOpen ? 1 : 0 }}
              primaryTypographyProps={{ fontSize: "14px" }}
            />
          </ListItemButton>
        </ListItem>
      )}

      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          selected={isSupportActive()}
          onClick={() => {
            navigate(
              `/support/?account=${
                selectedAccount ? selectedAccount.toLowerCase() : null
              }`
            );
          }}
          sx={{
            minHeight: 44,
            justifyContent: drawerOpen ? "initial" : "center",
            px: 2,
            py: 0.5,
            borderRadius: 1.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen ? 1.8 : "auto",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Support" placement="right">
              <ContactSupportIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            id="support"
            primary={"Support"}
            sx={{ opacity: drawerOpen ? 1 : 0 }}
            primaryTypographyProps={{ fontSize: "14px" }}
          />
        </ListItemButton>
      </ListItem>
      {permissions?.canAccessSettings && (
        <>
          <ListItem disablePadding sx={{ display: "block", mt: 1 }}>
            <ListItemButton
              onClick={handleClick}
              selected={isSettingsActive() || isAccountManagementActive()}
              sx={{
                minHeight: 44,
                justifyContent: drawerOpen ? "initial" : "center",
                px: 2,
                py: 0.5,
                borderRadius: 1.5,
              }}
              data-cy="settings-button"
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: drawerOpen ? 1.8 : "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Settings" placement="right">
                  <Settings />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                id="settings"
                primary={"Settings"}
                sx={{ opacity: drawerOpen ? 1 : 0 }}
                primaryTypographyProps={{ fontSize: "14px" }}
              />
              {drawerOpen ? openSettings ? <ExpandLess /> : <ExpandMore /> : ""}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem disablePadding sx={{ mt: 1 }}>
                <ListItemButton
                  data-cy="store-configuration"
                  selected={isSettingsActive()}
                  onClick={() => {
                    navigate(
                      `/settings?account=${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 38,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemText
                    id="store-configuration"
                    primary={"Configuration"}
                    sx={{
                      opacity: drawerOpen ? 1 : 0,
                      pl: 4.7,
                    }}
                    color="primary"
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ mt: 1 }}>
                <ListItemButton
                  data-cy="account-management"
                  onClick={() => {
                    navigate(`/account-management`);
                  }}
                  selected={isAccountManagementActive()}
                  sx={{
                    minHeight: 38,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemText
                    id="account-management"
                    primary={"Account Management"}
                    sx={{ opacity: drawerOpen ? 1 : 0, pl: 4.7 }}
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </>
      )}

      <ListItem disablePadding sx={{ display: "block", mt: 1 }}>
        <ListItemButton
          onClick={handleLogout}
          sx={{
            minHeight: 44,
            justifyContent: drawerOpen ? "initial" : "center",
            px: 2,
            py: 0.5,
            borderRadius: 1.5,
          }}
          data-cy="logout-button"
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen ? 1.8 : "auto",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Logout" placement="right">
              <Logout />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            id="logout"
            primary={"Logout"}
            sx={{ opacity: drawerOpen ? 1 : 0 }}
            primaryTypographyProps={{ fontSize: "14px" }}
            secondary={userEmail}
            secondaryTypographyProps={{
              style: {
                color: "#2f5597",
                fontSize: 12,
                fontWeight: "bold",
              },
            }}
          />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ display: "block", width: "100%", mt: 2 }}>
        <ListItemButton
          onClick={handleLeftDrawerToggle}
          sx={{
            width: "100%",
            transition: "all .2s ease-in-out",
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            "&:hover": {
              background: theme.palette.secondary.light,
              color: theme.palette.primary.light,
            },
            minHeight: 44,
            justifyContent: drawerOpen ? "initial" : "center",
            px: 2,
            py: 0.5,
            borderRadius: 1.5,
            textAlign: "center",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              m: "auto",
              justifyContent: "center",
            }}
          >
            {drawerOpen ? <ChevronLeft /> : <ChevronRight />}
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </List>
  );
}
