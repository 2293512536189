import { useState } from "react";
import { useAuthentication } from "../authentication/useAuthentication";
import { IGenericHookProps } from "@/shared/type";
import useNotification from "@/hooks/useNotification";
import { defaultErrorNotification, handleInternalServerError } from "@/utils/errorHandler";
import useFetchStore from "./useFetchStore";
import { sentryCaptureException } from "@/services/sentry.service";
import { SENTRY_TAG_NAME } from "@/config/sentry.config";
import { MESSAGES } from "@/config/dialogMessages.config";
import { API_RESPONSE } from "@/config/apiResponse.config";
import { storeApi } from "@/store/api/store";
import { removeStorage } from "@/utils/storage.util";
import { INTERNAL_SERVER_STORAGE_ERROR } from "@/config/global.config";
import { AxiosError } from "axios";

type AccountError = { type: string; message: string; }[];

export default function useAddNewStore({
  onSuccess,
  onFailure,
}: IGenericHookProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useAuthentication();
  const { addNotification } = useNotification();
  const { refetchStoreData } = useFetchStore();
  const [addStore] = storeApi.endpoints.addStore.useLazyQuery();
  const addNewStore = async ({
    storeName,
    code = "",
  }: {
    storeName: string;
    code?: string;
  }) => {
    setIsLoading(true);

    try {
      const createdStore = await addStore({
        access_token: accessToken!,
        store: storeName,
        code,
      }).unwrap();
      
      removeStorage(INTERNAL_SERVER_STORAGE_ERROR);
      refetchStoreData();
      onSuccess?.();

      return createdStore.id;
    } catch (e: any) {
      onFailure?.();
      if (e?.response?.status === 409) {
        const messagePayload = MESSAGES.NEW_STORE.ALREADY_EXITS({ storeName });
        addNotification({
          title: messagePayload.TITLE,
          message: messagePayload.MESSAGE,
          type: "error",
        });
      } else if (
        e?.response?.data?.type === API_RESPONSE.STORE_VALIDATION_FAILURE
      ) {
        const validationErrors = e?.response?.data?.validationErrors;
        const codeError = validationErrors?.Code || [];
        const invalidCodeError = codeError.find((e: any) => e?.type === API_RESPONSE.STORE_CREATION_CODE_INVALID);

        if (invalidCodeError || validationErrors?.account?.length) {
          const _message = invalidCodeError?.message
            || (validationErrors?.account?.length
              && (validationErrors?.account as AccountError).reduce((acc, arr) => {
                acc += `${arr.message} `;

                return acc;
              }, ""))
          const message = MESSAGES.GENERAL_ERROR_MESSAGE_CUSTOMIZED({
            message: _message
          });

          addNotification({
            title: e?.response?.data?.title || "Oops!",
            message,
            type: "error",
          });
        } else {
          defaultErrorNotification({ addNotification });
        }
      } else if (e?.response?.status === 500) {
        handleInternalServerError({ addNotification, error: e as unknown as AxiosError });
      } else {
        defaultErrorNotification({ addNotification });
      }

      sentryCaptureException({
        tag: {
          name: SENTRY_TAG_NAME.API_NAME,
          value: "Add New Store",
        },
        error: e,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    addNewStore,
    isLoading,
  };
}
