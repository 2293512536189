import "./style.css";

export default function ErrorCross() {
  return (
    <div className="wrapper">
      <svg
        className="cross"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle className="cross__circle" cx="26" cy="26" r="25" fill="none" />
        <path
          className="cross__cross"
          fill="none"
          d="M16 16, 36 36 M16 36, 36 16"
        />
      </svg>
    </div>
  );
}
