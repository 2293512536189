import ReactDOM from "react-dom/client";
import App from "./App";
import "@/assets/scss/style.scss";
import Auth0ProviderWithNavigate from "./providers/Auth0Provider/auth0-provider-with-navigate";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "@/store";
import * as Sentry from "@sentry/react";
import config from "@/config/redux.config";
import { APP_ENV, SENTRY_SDN } from "./config/env.config";
import { useEffect } from "react";

if (SENTRY_SDN) {
  Sentry.init({
    dsn: SENTRY_SDN,
    environment: APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// ==============================|| REACT DOM RENDER  ||============================== //

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </Provider>
);

// reportWebVitals(console.info);
